<template>
  <div>
    <section class="hero" v-if="this.$route.params.children === undefined">
      <img :src="url + image" width="1920" height="536" alt="hero" />
      <h1 class="hero__title">
        <v-lazy-image
          :src="icon"
          rel="preload"
          width="73"
          height="58"
          alt="hero-icon"
        />
        {{ title }}
      </h1>
    </section>
    <section class="hero" v-if="this.$route.params.children">
      <img :src="url + NewData.image" width="1920" height="536" alt="hero" />
      <h1 class="hero__title">
        <v-lazy-image
          :src="NewData.icon"
          rel="preload"
          width="73"
          height="58"
          alt="hero-icon"
        />
        {{ NewData.title }}
      </h1>
    </section>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image";

export default {
  components: {
    VLazyImage,
  },
  props: {
    url: {
      type: String,
      default: process.env.VUE_APP_API,
    },
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      NewData: [],
    };
  },
  created: function () {
    window.mitt.on("childrenData", (NewData) => {
      this.NewData = NewData;
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.hero {
  position: relative;


  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #000000;
    opacity: 0.25;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-bottom: 2px solid #f9b80e;
    opacity: 0.4;
  }

  > img {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 286px;
    object-fit: cover;
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    font-weight: 700;
    @include property("font-size", 48, 26);
    line-height: calc(62 / 48);
    letter-spacing: 2.4px;
    color: #f9b80e;
    text-transform: uppercase;
    padding: 15px;

    @media (max-width: 420px) {
      flex-direction: column;
    }

    img {
      display: block;
      flex: 0 0 auto;
      width: 73px;
      height: 58px;
      margin-right: 20px;
      font-size: 15px;

      @media (max-width: 420px) {
        margin: 0 0 15px;
      }
    }
  }
}
</style>
